import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  logoutAPI,
  getAllHistory,
  adjustmentDefaultValues,
} from "../../Services/ApiServices";
import { toast } from "react-toastify";
import logo from "../../Common/img/header-logo.png";
import logo2 from "../../Common/img/header-logo2.png";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  csvNewData,
  changeDefaultValues,
  CountValue,
  review_data,
  formula_Division_DefaultValue,
  report_data,
  base64ImagesData,
  loginState,
  propertyImages,
  selectedPropertydata,
  selectedPropertydataUUID,
  addressTitle,
  userAddedDefaultValues,
  allCsvData,
  selectedAdditionalInfo,
  comaparableData,
  selectPropertyList,
  adjustmentFormulas,
  ListArray,
  InputAddressState,
  businessImage,
  pageChecked,
  UploadCSVFlag,
  selectedPropertyAddress,
  clientName,
  ReportName,
} from "../../Redux/actions";
import "./Header.scss";
import Loader from "../../Components/Loader/Loader";
import { changeFormateOfApiData, defaultStateValue } from "../../utils/helper";

const Header = (props) => {
  const [historydata, setHistoryData] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const profilePic = useSelector((state) => state?.Login?.profileImage);

  const historyUpdate = (e) => {
    defaultValuesApiCallAandData();
    const test = JSON.parse(e.state);
    dispatch(csvNewData(test.csvNewData));
    dispatch(changeDefaultValues(test.changeDefaultValues));
    dispatch(CountValue(test.count));
    dispatch(review_data(test.review_data));
    dispatch(formula_Division_DefaultValue(test.formula_Division_DefaultValue));
    dispatch(report_data(test.report_data));
    dispatch(selectedPropertydata(test.selectedPropertydata));
    dispatch(selectedPropertydataUUID(test.selectedPropertydataUUID));
    dispatch(addressTitle(test?.address));
    dispatch(selectedPropertyAddress(test?.selectedPropertyAddress));
    dispatch(pageChecked(test?.checked));
    dispatch(allCsvData(test.allCsvData));
    dispatch(selectedAdditionalInfo(test.selectedAdditionalInfo));
    dispatch(propertyImages(test.propertyImages));
    dispatch(comaparableData(test.comaparables));
    dispatch(selectPropertyList(test.selectPropertyList));
    dispatch(adjustmentFormulas(test.adjustment_formulas));
    dispatch(ListArray(test.listing_id_arr));
    dispatch(InputAddressState(test.inputAddressState));
    dispatch(businessImage(test.businessImages));
    dispatch(UploadCSVFlag(test.isUploadCSV));
    dispatch(base64ImagesData(test.base64Images));
    dispatch(clientName(test.clientName));
    dispatch(ReportName(test.reportName));
    navigate("/confirm-subject");
  };
  const defaultValuesApiCallAandData = () => {
    adjustmentDefaultValues()
      .then((res) => {
        if (res.status_code === 200) {
          if (res.data) {
            const userdataAPIdata = Object.entries(
              res.data.user_property_adjustment_value
            ).map((e) => e[1]);

            let userAddedValues = changeFormateOfApiData(
              userdataAPIdata,
              defaultStateValue
            );
            if (Object.values(userAddedValues).length <= 17) {
              dispatch(userAddedDefaultValues(userAddedValues));
            }
          }
        } else {
          if (res.data && res.data.error) {
            toast.error(res.data.error);
          }
        }
      })
      .catch((error) => {});
  };

  const logout = (e) => {
    e.preventDefault();
    logoutAPI()
      .then((res) => {
        if (res.status_code === 200) {
          dispatch(propertyImages([]));
          dispatch(base64ImagesData([]));
          toast.success("User Logged out Successfully");
          localStorage.clear();
          navigate("/login");
          dispatch(loginState("login"));
        } else {
          if (res.data && res.data.error) {
            toast.error(res.data.error);
          } else {
            for (var iRow in res.data) {
              if (Object.keys(res.data[iRow]).length >= 1) {
                for (var jRow in res.data[iRow]) {
                  toast.error(res.data[iRow][jRow]);
                }
              }
            }
          }
        }
      })
      .catch(() => {
        toast.error("Something went wrong.");
        return false;
      });
  };

  // getAll history  api
  const getHistoryApi = () => {
    setLoader(true);
    getAllHistory()
      .then((res) => {
        if (res.status_code === 200) setHistoryData(res.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  };
  useEffect(() => {
    const testtoken = localStorage.getItem("token");
    if (testtoken) {
      getHistoryApi();
    }
  }, []);
  return (
    <Fragment>
      <div className="header-page">
        {!props.isLandingHeader ? (
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link to={"/home"} className="navbar-brand">
              <img src={logo} alt="logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon mt-1"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">
                <li>
                  <Link
                    className={
                      location.pathname === "/home"
                        ? "nav-link activeNav"
                        : "nav-link"
                    }
                    to="/home"
                  >
                    Home
                    <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <Dropdown className="mx-3 dropdown">
                  <Dropdown.Toggle
                    variant=""
                    style={{
                      background: "transparent",
                      color: "#1a28a2",
                      fontWeight: "500",
                    }}
                  >
                    HISTORY
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="menu">
                    {loader ? (
                      <Loader width={"50px"} height={"50px"} />
                    ) : (
                      <>
                        {historydata?.length > 0 ? (
                          historydata?.map((element) => {
                            return (
                              <Dropdown.Item
                                onClick={() => historyUpdate(element)}
                              >
                                {element.address}
                              </Dropdown.Item>
                            );
                          })
                        ) : (
                          <div style={{ paddingLeft: "5px" }}>No History</div>
                        )}
                      </>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
                <li>
                  <Link
                    to="/change-default-values"
                    className={
                      location.pathname === "/change-default-values"
                        ? "nav-link activeNav"
                        : "nav-link"
                    }
                  >
                    Change default values
                  </Link>
                </li>

                <li>
                  <Link
                    className={
                      location.pathname === "/faqs"
                        ? "nav-link activeNav"
                        : "nav-link"
                    }
                    to="/faqs"
                  >
                    FAQS
                    <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="navItem">
                  <a className="nav-link" onClick={logout} href="/#">
                    Log Out
                  </a>
                </li>
                <li className="navItem">
                  <span className="navItem">
                    <Link to="/edit-profile">
                      <img
                        alt="profile pic"
                        src={profilePic ? profilePic : "/user.png"}
                        className="user-img"
                      />
                    </Link>
                  </span>
                </li>
              </ul>
            </div>
          </nav>
        ) : (
          <div className="container c-container">
            <nav className="navbar navbar-expand-lg navbar-light border-0">
              <Link to={"/home"} className="navbar-brand">
                <img src={logo2} alt="logo" />
              </Link>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon mt-1"></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li
                    className={
                      location.pathname === "/"
                        ? "navItem activeNav"
                        : "navItem"
                    }
                  >
                    <Link
                      to={"/"}
                      className={
                        location.pathname === "/"
                          ? "nav-link activeNav"
                          : "nav-link"
                      }
                    >
                      Home
                      <span className="sr-only">(current)</span>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/about-us"}
                      className={
                        location.pathname === "/about-us"
                          ? "nav-link activeNav"
                          : "nav-link"
                      }
                    >
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link
                      to={"/contact-us"}
                      className={
                        location.pathname === "/contact-us"
                          ? "nav-link activeNav"
                          : "nav-link"
                      }
                    >
                      CONTACT US
                    </Link>
                  </li>
                  <li className="navItem">
                    <Link
                      to={"/subscription-plan"}
                      className={
                        location.pathname === "/subscription-plan"
                          ? "nav-link activeNav"
                          : "nav-link"
                      }
                    >
                      Subscription
                    </Link>
                  </li>
                  <li className="navItem">
                    <Link
                      to={"/home"}
                      className={
                        location.pathname === "/home"
                          ? "nav-link activeNav"
                          : "nav-link"
                      }
                      onClick={props.scrollToFAQ}
                    >
                      FAQ
                    </Link>
                  </li>
                  <button
                    className="btn btn-blue btn-outline-success mx-1 mb-1"
                    onClick={() => {
                      navigate("/login");
                      dispatch(loginState("login"));
                    }}
                  >
                    Sign in
                  </button>
                  <button
                    className="btn btn-blue btn-outline-success mx-1"
                    onClick={() => {
                      navigate("/subscription-plan");
                      dispatch(loginState("register"));
                    }}
                  >
                    Sign up
                  </button>
                  <button
                    className="btn btn-blue btn-outline-success mx-1 pinkBtn"
                    onClick={() => navigate("/request-demo")}
                  >
                    Request a Demo
                  </button>
                </ul>
              </div>
            </nav>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default Header;
